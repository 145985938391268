<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4 body">
                <audio id="myAudio" controls hidden autoplay loop>
                    <source src="../assets/music/background_music.mp3" type="audio/mp3">
                </audio>
                <center class="">
                    <form>
                    <img src="../assets/img/golden_logo.png" alt="" class="img-fluid"/>
                    <h3 class="text-center text-white">Login</h3>
                    <div class="form-group mb-4">
                        <input type="number" placeholder="Mobile Number" id="username" v-model="user.username" required class="form-control" />
                    </div>
                    <div class="form-group mb-4">
                        <input type="password" placeholder="Password" v-model="user.password" class="form-control form-group" minlength="6" required />
                        <div class="underline"></div>
                    </div>
                    <button class="btn btn-success form-control button text-white" @click.prevent="onLogin()">Login</button>
                </form>
                <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
            <div class="van-toast__text">success</div>
        </div>
        <div data-v-74fec56a="" data-v-309ccc10="" id="loading" class="loading" style="display: none;">
            <div data-v-74fec56a="" class="v-dialog v-dialog--persistent" style="width: 300px; display: block;">
                <div data-v-74fec56a="" data-v-5197ff2a="" class="v-card v-sheet theme--dark teal">
                    <div data-v-74fec56a="" data-v-5197ff2a="" class="v-card__text"><span
                            data-v-74fec56a="">Loading</span>
                        <div data-v-74fec56a="" data-v-5197ff2a="" role="progressbar" aria-valuemin="0"
                            aria-valuemax="100" class="v-progress-linear mb-0" style="height: 7px;">
                            <div data-v-74fec56a="" class="v-progress-linear__background white"
                                style="height: 7px; opacity: 0.3; width: 100%;"></div>
                            <div data-v-74fec56a="" class="v-progress-linear__bar">
                                <div data-v-74fec56a=""
                                    class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active">
                                    <div data-v-74fec56a="" class="v-progress-linear__bar__indeterminate long white">
                                    </div>
                                    <div data-v-74fec56a="" class="v-progress-linear__bar__indeterminate short white">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                        <div class="nav">
                            <div class="m-3">
                                <button class="btn btn-sm btn-warning" @click="$router.push('register')">Forgot Password</button>
                            </div>
                        
                            <div class=" m-3">
                                <!-- <a href="/register"> -->
                                    <button class="btn btn-sm btn-warning" @click="$router.push('register')">Create An Account</button>
                                <!-- </a> -->
                            </div>
                        </div>
                
                </center>
            </div>
            <div class="col-md-4"></div>
        </div>
    </div>
  </template>
  
  <script>
import axios from "axios";
export default {
    name: "LoginPage",
    data() {
        return {
            user: {
                username: null,
                password: null
            }
        }

    },
    beforeCreate: function () {
        if (localStorage.getItem('username') != null)
            this.$router.push({ name: 'Home' });
    },

    methods: {
        pop(message) {
            document.getElementById("snackbar").innerHTML = message;
            document.getElementById("snackbar").style.display = "";
            setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 3000);
        },
        loading() {
            document.getElementById("loading").style.display = "";
            setTimeout(function () { document.getElementById("loading").style.display = "none"; }, 500);
        },
        onLogin() {
            if (this.user.username == null || this.user.password == null) {
                if (this.user.username == null) {
                    this.pop("Mobile number is requied")
                } else {
                    this.pop("Password is requied")
                }

            } else {
                var data = new FormData();
                data.append("username", this.user.username);
                data.append("password", this.user.password);
                axios
                    .post(
                        "https://rushground.in/backend/api/register.php?login",
                        data
                    )
                    .then(res => {
                        if (res.data.error== true) {
                            this.loading();
                            localStorage.setItem('username', this.user.username);
                            console.log("Success", res.data.message);
                            this.pop(res.data.message);
                            setTimeout(() => { this.$router.push("Home"); }, 600);
                        } else {
                            
                            this.loading();
                            console.log("Error", res.data);
                            
                        }
                    })
                    .catch(err => {
                        console.log("Error", err);
                    });
            }
        },
        redirect() {
            console.log(this.$store.state.name);
            //this.$router.push({ name: 'Search' });
        },

    }




}
</script>