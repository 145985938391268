<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4 body">
                <audio id="myAudio" controls hidden autoplay loop>
                    <source src="../assets/music/background_music.mp3" type="audio/mp3">
                </audio>
                <center class="mt-5">
                    <h1 class="text-warning">WELCOME TO</h1>
                    <img src="../assets/img/golden_logo.png" class="img-fluid" alt="">
                    <p class="text-warning text-capitalize"><strong>RUSHGROUND</strong> is a gaming plateform where you can win prizes and gifts by playing tournaments.</p>
                    <div class="pt-5">
                        <button class="btn btn-warning form-control" @click="$router.push('login')">Get Started</button>
                    </div>
                </center>
            </div>
            <div class="col-md-4"></div>
        </div>
    </div>
  </template>
  
  <script>
export default {
  name: 'WalcomeJs',
};

</script>