import { createApp } from 'vue'
import App from './App.vue'
// Import Bootstrap CSS files
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import Home from './components/Home.vue'
import Index from './components/Index.vue'
import Welcome from './components/Welcome.vue'
import Login from './components/Login.vue'
import Register from './components/Register.vue'
import Otp from './components/Otp.vue'
require('./assets/css/style.css');
import {createRouter, createWebHistory} from 'vue-router'  

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
   
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
   
  },
  {
    path: '/otp',
    name: 'Otp',
    component: Otp,
   
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
   
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
   
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: Register, 
   
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
createApp(App).use(router).mount('#app')
