<template>
    <div>
        <h1>Index</h1>
    </div>
</template>
<script>
    export default {
        name: 'IndexPage'
    }
</script>
<style>
    
</style>