<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  export default {
    name: 'App'
  }
</script>
<style>
  nav{
    padding:10px;

  }
  nav a{
    margin-right: 10px;
  }
</style>
