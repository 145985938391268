<!-- src/components/OtpInput.vue -->
<template>
  <div class="otp-container">
    <div>
        <img src="../assets/img/golden_logo.png" class="img-fluid" alt="">
    </div>
    <h2 class="text-warning">Enter OTP</h2>
    <div class="otp-inputs">
      <input
        v-for="(digit, index) in otp"
        :key="index"
        type="text"
        class="otp-input"
        maxlength="1"
        v-model="otp[index]"
        @input="moveFocus(index)"
      />
    </div>
    <button class="btn btn-success mt-3 form-control otp-button" @click="submitOtp">Verify</button>
  </div>
</template>

<script>
export default {
    name: 'OtpPage',
  data() {
    return {
      otp: ['', '', '', '', '', '']
    };
  },
  methods: {
    moveFocus(index) {
      if (this.otp[index].length === 1 && index < this.otp.length - 1) {
        this.$refs[`otp${index + 1}`][0].focus();
      }
    },
    submitOtp() {
      const otpCode = this.otp.join('');
      alert(`OTP Submitted: ${otpCode}`);
      // Here, you can handle the OTP submission (e.g., send it to the server)
    }
  }
};
</script>

<style>

</style>
