<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div id="demo" class="carousel slide" data-ride="carousel">

                <!-- Indicators -->
                <ul class="carousel-indicators">
                <li data-target="#demo" data-slide-to="0" class="active"></li>
                <li data-target="#demo" data-slide-to="1"></li>
                <li data-target="#demo" data-slide-to="2"></li>
                </ul>

                <!-- The slideshow -->
                <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="https://playerzon.com/listgames/slider/bgmi-min.jpg" class="img-fluid" alt="Los Angeles">
                </div>
                </div>

                <!-- Left and right controls -->
                <a class="carousel-control-prev" href="#demo" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#demo" data-slide="next">
                <span class="carousel-control-next-icon"></span>
                </a>

                </div>
                <div class="container-fluid">
                    <div class="row text-center p-2 bg-warning">
                        <div class="col-3">
                            <div class="category">
                                <img src="../assets/img/category/uc.png" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="category">
                                <img src="../assets/img/category/uc.png" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="category">
                                <img src="../assets/img/category/uc.png" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="category">
                                <img src="../assets/img/category/uc.png" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="row text-center mt-3 p-2 bg-warning">
                        <div class="col-6">
                            <div class="bgmi-link">
                                <img src="../assets/img/bgmi-logo.png" alt="">
                            </div>
                        </div>
                        <div class="col-6">
                           <div class="bgmi-link">
                            <a href="https://play.google.com/store/apps/details?id=com.pubg.imobile" target="_blank">
                            <button><strong>Play Android</strong></button>
                           </a>
                           <a href="https://apps.apple.com/in/app/battlegrounds-mobile-india/id1526436837" target="_blank">
                            <button><strong>Play IOS</strong></button>
                           </a>
                        </div>
                        </div>
                        
                    </div>
                    <div class="row fixed-bottom bg-warning text-center">
                        
                        <div class="col-3">
                            <img src="../assets/img/icons/profile.png" alt="">
                            <p>HOME</p>
                        </div>
                        <div class="col-3">
                            <img src="../assets/img/icons/profile.png" alt="">
                            <p>LIVE</p>
                        </div>
                        <div class="col-3">
                            <img src="../assets/img/icons/profile.png" alt="">
                            <p>MY PALYS</p>
                        </div>
                        <div class="col-3">
                            <img src="../assets/img/icons/profile.png" alt="">
                            <p>PROFILE</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4"></div>
        </div>
    </div>
    
    
</template>
<script>
    export default {
        name: 'HomePage',
        methods:{
            logout() {
            localStorage.removeItem('username')
            this.$router.push("/login");
            
        },
        }
        
    }
</script>
<style>
</style>